import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import Handsontable from 'handsontable';

const iconCompleted = '✅'; // Example for completed (checkmark icon)
const iconCancelled = '❌'; // Example for cancelled (cross icon)

const OrdersTable = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [size] = useState(50); // Default number of rows per page (changed to 50)
  const [totalPages, setTotalPages] = useState(0); // Total number of pages

  // Fetch paginated data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        //const response = await axios.get(`https://ttlbyte.me/api/orders`, {
        const response = await axios.get('/api/orders', {
          params: { size, page }
        });
        const data = response.data;

        // Assuming the API provides total number of rows to calculate total pages
        const totalRecords = response.headers['x-total-count'] || 1000; // Adjust this if your API returns total count differently
        const totalPages = Math.ceil(totalRecords / size);

        setOrders(data);
        setTotalPages(totalPages);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [page, size]); // Re-fetch data when page changes

  // Format data for Handsontable in the new order
  const formatData = (orders) => {
    return orders.map(order => [
      order.order_id,      // order_id
      order.order_date,    // order_date
      order.ship_to,       // ship_to
      order.completed,     // completed
      order.account,       // account
      order.tracking,      // tracking
      order.url            // url (for internal use only, hidden column)
    ]);
  };

  // Custom renderer for order_id to display as a clickable hyperlink
  const hyperlinkRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    Handsontable.dom.empty(td); // Clear the cell

    const rowData = instance.getSourceDataAtRow(row); // Get the full row data
    let url = rowData[6]; // Get the URL (8th column in our data array)
    const orderId = rowData[0]; // Get the order_id (1st column in our data array)
    //if (url && url.includes('&')) {
    //  url = url.split('&')[0];
    //};

    if (url) {
      const a = document.createElement('a');
      a.href = url;
      a.textContent = orderId;
      a.target = '_blank'; // Open link in a new tab
      td.appendChild(a);
    } else {
      td.textContent = orderId; // Fallback to plain text if no URL
    }
  };

  // Custom renderer for icons in cancelled and completed columns
  const iconRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    Handsontable.dom.empty(td); // Clear the cell

    const icon = value === 1 ? (col === 3 ? iconCompleted : iconCompleted) : iconCancelled; // Display appropriate icon
    td.textContent = icon; // Add the icon
  };

   const beforeCopy = (data, coords) => {
    // Modify the data array to include the order_id as a hyperlink
    coords.forEach(range => {
      const { startRow, endRow, startCol, endCol } = range;

      // Loop over the range of rows and columns being copied
      for (let row = startRow; row <= endRow; row++) {
        for (let col = startCol; col <= endCol; col++) {
          if (col === 0) { // Modify only the order_id column
            const orderId = data[row][col]; // Get the order_id
            let url = orders[row]?.url;   // Get the associated URL

            if (url) {
              // Create a formatted string with the URL attached to order_id
              //
            //  if ( url.includes('&')) {
             //   url = url.split('&')[0];
              //  };
              //const encodedUrl = encodeURI(url);
              // Format for Excel's HYPERLINK function
              data[row][col] = `=HYPERLINK("${url}", "${orderId}")`;
            }
          }
        }
      }
    });
  };

  // Define columns in the new order with necessary renderers
  const columns = [
    {
      title: 'Order ID',
      renderer: hyperlinkRenderer  // Use the custom hyperlink renderer for order_id
    },
    { title: 'Order Date' },
    { title: 'Ship To' },
    {
      title: 'Completed',
      renderer: iconRenderer  // Use the custom icon renderer for completed
    },
    { title: 'Account' },
    { title: 'Tracking' },
    { title: 'URL' } // This will be hidden using the hiddenColumns plugin
  ];

  // Pagination controls
  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(prevPage => prevPage - 1);
    }
  };

  return (
    <div>
      <h1>Orders Table</h1>
      {orders.length > 0 ? (
        <>
          <HotTable
            data={formatData(orders)}
            colHeaders={columns.map(col => col.title)}
            columns={columns}
            width="100%"
            height="auto"
            rowHeaders={true}
            licenseKey="non-commercial-and-evaluation"
            stretchH="all" // Stretch columns to fill width
            renderAllRows={false} // Enable virtual rendering for better performance
            autoColumnSize={true} // Enable automatic column resizing based on content
            autoRowSize={true} // Enable automatic row resizing
            manualColumnResize={true} // Allow column resizing if needed
            manualRowResize={true} // Allow row resizing if needed
            columnSorting={true} // Enable sorting for columns
            filters={true} // Enable filters for each column
            beforeCopy={beforeCopy}
            dropdownMenu={true} // Show dropdown menus for filter options

            // Use hiddenColumns plugin to hide the URL column
            hiddenColumns={{
              columns: [6], // Index of the URL column
              indicators: false // Disable the UI indicator for hidden columns
            }}
            // Ensure the necessary plugins are enabled
            plugins={['hiddenColumns', 'filters', 'columnSorting']}
          />
          {/* Pagination Controls */}
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <button onClick={handlePrevPage} disabled={page === 1}>
              Previous
            </button>
            <span style={{ margin: '0 20px' }}>Page {page} of {totalPages}</span>
            <button onClick={handleNextPage} disabled={page >= totalPages}>
              Next
            </button>
          </div>
        </>
      ) : (
        <p>Loading...</p> // Show a loading indicator until data is ready
      )}
    </div>
  );
};

export default OrdersTable;

